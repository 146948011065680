<template>
  <div class="gdi-container">
    <p class="text-2xl mb-6">Statistiques d'utilsation</p>
    <filters :progress="progress" />
    <g-d-usage-stats-table />
  </div>
</template>
<script>
import Filters from "@/components/google-discover/gd-usage-stats/filters/Filters.vue";
import GDUsageStatsTable from "@/components/google-discover/gd-usage-stats/tables/GDUsageStatsTable.vue";
import debounce from "lodash/debounce";
import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import axios, { cacheTime } from "@axios";

export default {
  name: "GDUsageStats",
  components: {
    Filters,
    GDUsageStatsTable,
  },
  data() {
    return {
      tab: null,
      loadingCount: 0,
      urlCalls: 3,
      progress: 0,
    };
  },
  setup() {
    const { isLoading, axiosGet } = useAxios();

    return {
      isLoading,
      axiosGet,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),

    async getData() {
      if (this.applyFilters && !this.isLoading) {
        const params = {
          dates: this.dates.join(","),
          sites: this.sites.join(","),
        };
        try {
          const {
            respSessionsInGD,
            respSessionsNotInGD,
            globalStats,
            weekdaysStats,
            keywordsStats,
            lifetimeStats,
          } = await runParallelAsyncs({
            respSessionsInGD: this.axiosGet(
              "google-discover/sessions-in-discover",
              params
            ),
            respSessionsNotInGD: this.axiosGet(
              "google-discover/sessions-not-in-discover",
              params
            ),
            globalStats: this.axiosGet("google-discover/global-stats", params),
            weekdaysStats: this.axiosGet(
              "google-discover/weekdays-stats",
              params
            ),
            keywordsStats: this.axiosGet(
              "google-discover/keywords-stats",
              params
            ),
            lifetimeStats: this.axiosGet(
              "google-discover/lifetime-stats",
              params
            ),
          });
          await this.$store.dispatch("gd/updateData", {
            globalStats: globalStats.data,
            sessionsInGD: respSessionsInGD.data,
            sessionsNotInGD: respSessionsNotInGD.data,
            weekdaysStats: weekdaysStats.data,
            keywordsStats: keywordsStats.data,
            lifetimeStats: lifetimeStats.data,
          });
        } catch (err) {
          console.warn(err);
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },

    countLoadingUrls(urls) {
      return urls["google-discover_insights"]["get"].length;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    dates() {
      return this.$store.getters["gd/getDates"];
    },
    sites() {
      return this.$store.getters["gd/getSites"];
    },
    loadingUrls() {
      return this.$store.getters["axios/getLoadingUrls"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    loadingUrls() {
      const count = this.countLoadingUrls(this.loadingUrls);
      this.progress = Math.round(
        ((this.urlCalls - count) / this.urlCalls) * 100
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-tabs-items {
  background-color: #f4f5fa;
}
/* remove links underline */
.gdi-container ::v-deep a {
  text-decoration: none !important;
}
.gdi-container ::v-deep tbody tr:nth-of-type(even) {
  background-color: rgba(0, 1, 100, 0.08);
}
.gdi-container ::v-deep tbody tr:hover {
  background-color: #10396f !important;
  td {
    color: white !important;
    a {
      color: white !important;
    }
  }
}
</style>
